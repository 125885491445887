<template>
  <v-app class="aon">
    <HeaderNav
      :not-full-screen="true"
      :routes="routes"
      site-title="Broker Portal"
      site-logo="aon_logo_red.svg"
      email="cwEmail"
      phone-number="cwPhoneNumber"
      :show-service-desk="false"
    >
      <template #account>
        <HeaderNavAccount />
      </template>
    </HeaderNav>
    <NavDrawer :not-full-screen="notFullScreen" />
    <v-main>
      <div class="content-view">
        <GlobalLoader v-if="loadingUserInfo" />
        <router-view
          v-else
          class="container full-width route"
          :class="routeClasses"
        />
      </div>
      <AppFooter />
      <DirtyFormDialog />
    </v-main>
    <Snackbar />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

import NavDrawer from '@aon/cfs-components/src/components/NavDrawer';
import GlobalLoader from '@aon/cfs-components/src/components/GlobalLoader';
import AppFooter from '@aon/cfs-components/src/components/AppFooter';
import Snackbar from '@aon/cfs-components/src/components/Snackbar';
import DirtyFormDialog from '@aon/cfs-components/src/components/Forms/DirtyFormDialog';
import HeaderNav from '@aon/cfs-components/src/components/HeaderNav';
import HeaderNavAccount from '@aon/cfs-user/src/components/HeaderNavAccount';
import ROUTE_ROLE_ACCESS from '@/lib/constants/routeRoleAccess';

export default {
  name: 'App',
  components: {
    NavDrawer,
    GlobalLoader,
    AppFooter,
    Snackbar,
    DirtyFormDialog,
    HeaderNav,
    HeaderNavAccount,
  },
  computed: {
    routes() {
      return [
        {
          to: { path: '/' },
          text: this.$t('leftDrawer.links.home'),
          icon: 'fal fa-home-lg-alt',
          hide: !this.userHasRole(ROUTE_ROLE_ACCESS.HOME),
        },
        {
          to: { name: 'Coverage Requests' },
          text: this.$t('leftDrawer.links.coverageRequests'),
          icon: 'fal fa-file-invoice',
          hide: !this.userHasRole(ROUTE_ROLE_ACCESS.COVERAGE_REQUESTS),
        },
        {
          to: { name: 'Vendors' },
          text: this.$t('leftDrawer.links.vendors'),
          icon: 'fal fa-file-certificate',
          hide: !this.userHasRole(ROUTE_ROLE_ACCESS.POLICIES),
        },
        {
          to: { name: 'Policies' },
          text: this.$t('leftDrawer.links.policies'),
          icon: 'fal fa-file-certificate',
          hide: !this.userHasRole(ROUTE_ROLE_ACCESS.POLICIES),
        },
        {
          to: { name: 'COI Review' },
          text: this.$t('leftDrawer.links.coiReview'),
          icon: 'fal fa-inbox',
          hide: !this.userHasRole(ROUTE_ROLE_ACCESS.COI_REVIEW),
        },
      ];
    },
    ...mapState('user', ['user']),
    ...mapState('auth', {
      userSignedIn: state => state.userSignedIn,
      loadingUserInfo: state => state.loadingUserInfo,
    }),
    ...mapState('companies', {
      company: state => state.company,
    }),
    fullScreen() {
      return this.$route.meta.fullScreen;
    },
    notFullScreen() {
      return !this.fullScreen;
    },
    routeClasses() {
      return {
        'route--full-screen': this.fullScreen,
      };
    },
  },
  async updated() {
    if (this.userSignedIn && !this.company) {
      this.$store.commit('companies/setCompanyId', this.user.accountIds[0]);
      await this.$store.dispatch('companies/getCompanyProfile');
      await this.$store.dispatch(
        'companies/getCompany',
        this.user.accountIds[0]
      );
    }
  },
  methods: {
    userHasRole(roles) {
      if (!this.user) {
        return false;
      }

      const userRoles = this.user.permissions.accountPermissions.roles;
      return roles.some(r => userRoles.includes(r));
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/main.scss';
.header-nav__logo {
  height: 22px;
  margin: 2px 10px 0 0;
}
</style>
